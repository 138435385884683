import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as toastMsg from '../../core/toastMessages';
import { fetchAlerts } from './actions';
import { selectAlerts, selectAlertsTotal } from './reducer';

export function* notifyAcknowledgedAlert() {
  const alerts = yield select(selectAlerts);
  const alertsTotal = yield select(selectAlertsTotal);
  yield alertsTotal > 5 && alerts.length < alertsTotal ? put(fetchAlerts(5)) : put(fetchAlerts());
  yield call(toastMsg.alertAcknowledged, 'Alert Acknowledged');
}

export function* watchOverviewSagas() {
  yield takeEvery('ACKNOWLEDGE_ALERT_SUCCESS', notifyAcknowledgedAlert);
}
