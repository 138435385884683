import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { isAuthenticated } from '../../store/authReducer';
import '../../styles/login.scss';
import LandingPageFooter from './components/LandingPageFooter';
import LandingPageHeader from './components/LandingPageHeader';
import LoginForm from './components/LoginForm';

const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const isUserAuthenticated = useSelector(isAuthenticated);

  useEffect(() => {
    if (isUserAuthenticated) {
      const redirect = parse(location.search).redirect || '/';

      history.push(redirect);
    }
  }, [isUserAuthenticated]);

  const isWashClub = window.location.hostname === 'portal.wash.club';
  const backgroundClass = isWashClub ? 'wash-club-login-container' : 'login-container';

  return (
    <div className={`container-fluid ${backgroundClass}`}>
      <div className="login-sidebar">
        <LandingPageHeader />
        <LoginForm />
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default Login;
