import '../../../styles/login.scss';
import LandingPageFooter from './LandingPageFooter';
import RegisterForm from './SetPasswordForm';
import SetPasswordHeader from './SetPasswordHeader';

const SetPassword = (props: any): JSX.Element => {
  const isWashClub = window.location.hostname === 'portal.wash.club';
  const backgroundClass = isWashClub ? 'wash-club-login-container' : 'login-container';

  return (
    <div className={`container-fluid ${backgroundClass}`}>
      <div className="register-dialog">
        <SetPasswordHeader />
        <RegisterForm match={props.match} history={props.history} location={props.location} />
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default SetPassword;
