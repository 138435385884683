import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import { selectSite } from '../store/authReducer';
import Login from '../views/login';
import ForgotPassword from '../views/login/components/ForgotPassword';
import SetPassword from '../views/login/components/SetPassword';
import AuthenticatedRoute from './AuthenticatedRoute';

const RootRoute = () => {
  const site = useSelector(selectSite);

  const redirect = () => {
    if (site) {
      return <Redirect to={`${site}/overview`} />;
    }

    return <Redirect to="site-selection" />;
  };

  return (
    <Switch>
      <Route exact path="/" render={redirect} />
      <Route path="/login" component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/set-password/:token" component={SetPassword} />
      <Route component={AuthenticatedRoute} />
    </Switch>
  );
};

export default RootRoute;
