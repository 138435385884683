import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import '../styles/toast-message.scss';
import ErrorNotificationText from './components/ErrorNotificationText';

const globalOptions = {
  timeOut: 1500,
  transitionIn: 'bounceIn',
  transitionOut: 'fadeOut',
};

export function generateToastr(toastrType, toastrTitle, toastrMsg, opts) {
  const toastrOptions = {
    ...globalOptions,
    icon: toastrType,
    status: toastrType,
    ...opts,
  };

  return toastr.light(toastrTitle, toastrMsg, toastrOptions);
}

export function handledApiError(error) {
  return generateToastr('error', 'Error', error);
}

export function apiError(error) {
  const errorName = get(error, 'name') || 'Error';
  const errorMessage = get(error, 'message');
  const errorData = get(error, 'data') || get(error, 'error');

  let errorMessageArray = [];

  if (typeof errorData === 'string') {
    errorMessageArray.push(errorData);
  }

  if (typeof errorData === 'object') {
    errorMessageArray = handleErrorObject(error, errorData);
  }

  if (errorMessage) {
    errorMessageArray.push(errorMessage);
  }

  return generateToastr('error', errorName, {
    timeOut: 4000,
    className: 'error-notification',
    component: <ErrorNotificationText errorMessageArray={errorMessageArray} />,
  });
}

function handleErrorObject(error, errorData) {
  let errorMessageArray = [];

  for (const key in errorData) {
    if (errorData.hasOwnProperty(key)) {
      let errorValue = errorData[key];

      if (typeof errorValue !== 'string') {
        errorValue = JSON.stringify(errorValue, null, 2);
      }

      errorMessageArray.push(key + ': ' + errorValue);
    }
  }

  if (!errorMessageArray.length) {
    let errorFallback = 'Unknown error';

    if (typeof error !== 'string') {
      errorFallback = JSON.stringify(error, null, 2);
    } else if (error) {
      errorFallback = error;
    }

    errorMessageArray.push(`Failed getting data from server\n${errorFallback}`);
  }

  return errorMessageArray;
}

export function taskAdded() {
  return generateToastr('success', 'Task Added', 'Task successfully added to task list');
}

export function alertAcknowledged() {
  return generateToastr('success', 'Alert Acknowledged', 'Way to go!');
}

export function workLogAdded(name) {
  return generateToastr('success', 'Success', `${name} clocked in`);
}

export function workLogClosed(name) {
  return generateToastr('success', 'Success', `${name} clocked out`);
}

export function templateDataLoaded() {
  return generateToastr('success', 'Template Data Loaded Successfully');
}

export function templateDataFailed(title, message) {
  const toastrTitle = title || 'Template Data Failed to Load';
  const toastrMsg = message || 'Loading has been cancelled';
  return generateToastr('error', toastrTitle, toastrMsg);
}
