import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import TableActionButtonsCell from '../../../core/components/TableActionButtonsCell';
import { selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import * as actions from '../../inventory/actions';
import { getApiStatus } from '../../../core/utils';
import InventoryTypeFilter from '../../inventory/components/InventoryTypeFilter';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import SearchBar from '../../../core/components/SearchBar';
import InventoryDetail from '../../inventory/containers/InventoryDetail';
import UserGroupPermissions, {
  addPermissionRefs,
} from '../../../core/components/UserGroupPermissions';

import {
  selectInventory,
  selectInventoryTypes,
  selectPage,
  selectPageSize,
  selectTotal,
  selectInventoryInEdit,
  selectFromDate,
  selectUntilDate,
  selectTypeFilter,
  //Added TODO: Remove regular types.
  selectEssentialPage,
  selectEssentialPageSize,
  selectEssentialParts,
  selectEssentialTotal,
  selectEssentialPartsInEdit,
} from '../../inventory/reducer';
import { Drawer, DrawerContainer } from '../../../core/components/Drawer';
import Button from '../../../core/components/Button';
import ButtonWithLoader from '../../../core/components/ButtonWithLoader';

export const EssentialParts = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const essentialParts = useSelector(selectEssentialParts);
  const nonEssentialParts = useSelector(selectInventory);
  const inventoryTypes = useSelector(selectInventoryTypes);
  const filter = useSelector(selectTypeFilter);
  const total = useSelector(selectEssentialTotal);
  const page = useSelector(selectEssentialPage);
  const pageSize = useSelector(selectEssentialPageSize);
  const siteId = useSelector(selectSiteExtId);
  const multiSites = useSelector(selectMultiSiteSelection);
  const [inventoryExpanded, setExpanded] = useState(false);
  const [highlightedRow, setHighlightedRow] = useState(0);
  const essentialPartsInEdit = useSelector(selectEssentialPartsInEdit);
  const inventoryAdded = useSelector((state) => getApiStatus(state, 'ADD_ESSENTIAL_PART'));
  const inventoryEdited = useSelector((state) => getApiStatus(state, 'SAVE_ESSENTIAL_PART'));
  const inventoryDeleted = useSelector((state) => getApiStatus(state, 'DELETE_ESSENTIAL_PART'));

  //Search variables
  const fromDate = useSelector(selectFromDate);
  const untilDate = useSelector(selectUntilDate);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsDetails, setSearchResultsDetails] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    updateInventory();
  }, [
    filter,
    siteId,
    multiSites,
    fromDate,
    untilDate,
    inventoryAdded,
    inventoryEdited,
    inventoryDeleted,
  ]);

  useEffect(() => {
    dispatch(actions.fetchInventoryTypes());
  }, [siteId]);

  useEffect(() => {
    setExpanded(false);
    setHighlightedRow(0);
  }, [filter, searchResults?.length]);

  const getInventoryPage = (selectedPage: any) => {
    dispatch(actions.fetchInventory({ selectedPage }));
  };

  const getPaginationInfo = () => {
    return {
      total: total,
      page: page,
      pageSize: pageSize,
      goToPage: getInventoryPage,
    };
  };

  const updateInventory = () => {
    dispatch(
      actions.fetchInventory({
        inventoryType: filter,
        fromDate: fromDate,
        untilDate: untilDate,
      })
    );
  };

  const addInventory = () => {
    const dialogProps = {
      onSubmit: (item: any) => dispatch(actions.addInventoryItem(item)),
      isNew: true,
      title: intl.formatMessage({ id: 'addEssentialPart' }),
      intl: intl,
      isEssentialPart: true,
    };
    dispatch(showDialog('UPSERT_INVENTORY', dialogProps));
  };

  const editInventory = (event: any, { _id }: any) => {
    dispatch(actions.getInventoryItem(_id));
    const dialogProps = {
      title: intl.formatMessage({ id: 'editInventoryItem' }),
      onSubmit: (item: any) => dispatch(actions.saveInventoryItem(item)),
      isNew: false,
      intl: intl,
    };
    dispatch(showDialog('UPSERT_INVENTORY', dialogProps));
  };

  const deleteInventory = (event: any, { _id, name }: any) => {
    const dialogProps = {
      onReady: () => {
        dispatch(actions.deleteInventoryItem({ id: _id }));
      },
      text: intl.formatMessage({ id: 'inventoryItemArchiveConfirmation' }, { name }),
      title: intl.formatMessage({ id: 'archiveInventoryItem' }),
    };
    dispatch(showDialog('CONFIRM_DIALOG', dialogProps));
  };

  const getRowClickInfo = () => ({
    callback: (data: { _id: React.SetStateAction<number> }) => {
      setExpanded(true);
      setHighlightedRow(data._id);
      dispatch(actions.getInventoryItem(data._id));
    },
  });

  const initializeDatatable = (initialInventory: any[]) => {
    const data = initialInventory.map(
      (i: {
        id: any;
        number: any;
        name: any;
        description: any;
        installedAt: moment.MomentInput;
        onHand: any;
        unitOfMeasure: any;
        priority: any;
      }) => {
        return {
          _id: i.id,
          partNumber: i.number,
          name: i.name,
          description: i.description,
          installedAt: i.installedAt
            ? moment(i.installedAt).format('MM/DD/YYYY')
            : intl.formatMessage({ id: 'notAvailable' }),
          onHand: i.onHand,
          unit: i?.unitOfMeasure || '',
          priority: i.priority.toString(),
        };
      }
    );

    const headers = [
      { name: intl.formatMessage({ id: 'partNumber' }) },
      { name: intl.formatMessage({ id: 'name' }) },
      { name: intl.formatMessage({ id: 'description' }) },
      { name: intl.formatMessage({ id: 'installedAt' }) },
      { name: intl.formatMessage({ id: 'inStock' }) },
      { name: intl.formatMessage({ id: 'unit' }) },
      { name: intl.formatMessage({ id: 'priority' }) },
      {
        name: intl.formatMessage({ id: 'action' }),
        cell: {
          component: TableActionButtonsCell,
          mixIns: {
            buttons: [
              { icon: 'icon-trash', buttonAction: deleteInventory },
              { icon: 'icon-edit', buttonAction: editInventory },
            ],
          },
        },
      },
    ];

    return {
      data,
      rowClickInfo: getRowClickInfo(),
      headers,
    };
  };

  const drawerControlsConfig = () => {
    return {
      closeFunction: () => {
        setExpanded(false);
        dispatch(actions.deleteInventoryInEdit());
      },
    };
  };

  const setSearchMatch = (
    sResults: React.SetStateAction<any>,
    sResultsDetails: React.SetStateAction<never[]>,
    sText: React.SetStateAction<string>
  ) => {
    setSearchResults(sResults);
    setSearchResultsDetails(sResultsDetails);
    setSearchText(sText);
  };

  const data = searchResults !== null ? searchResults : essentialParts.concat(nonEssentialParts);

  return (
    <div data-testid="essentialPartsContainer" className="essential-parts-container">
      <header className="d-flex justify-content-between align-items-center mb-3">
        <DateRangeSelector
          dates={[fromDate, untilDate]}
          onSubmit={(dates) => {
            dispatch(actions.setFromDate(dates.from));
            dispatch(actions.setUntilDate(dates.until));
          }}
        />
        <div className="page-action-buttons">
          <UserGroupPermissions itemRef={addPermissionRefs.inventory}>
            <ButtonWithLoader style="button add-task" handleClick={() => addInventory()}>
              <>
                <i className="icon icon-plus"></i>
                <span data-testid="addEssentialPartButton" className="add-button-text">
                  {intl.formatMessage({ id: 'addEssentialPart' })}
                </span>
              </>
            </ButtonWithLoader>
          </UserGroupPermissions>
        </div>
      </header>
      <div className="table-filters">
        <InventoryTypeFilter inventoryTypes={inventoryTypes} />
        <SearchBar
          intl={intl}
          dataToSearchOn={essentialParts}
          dataToSearchFor={['name', 'number', 'description']}
          setSearchMatch={setSearchMatch}
          uniqueField="id"
          styles={['data-table-search']}
        />
      </div>
      <DrawerContainer>
        <div>
          <DataTable
            paginationInfo={getPaginationInfo()}
            highlightedRow={highlightedRow}
            scrolling
            {...initializeDatatable(data)}
          />
        </div>
        <Drawer expanded={inventoryExpanded}>
          <div className="inventory-details-wrapper">
            {essentialPartsInEdit && (
              <InventoryDetail isPage={false} drawerControls={{ ...drawerControlsConfig() }} />
            )}
          </div>
        </Drawer>
      </DrawerContainer>
    </div>
  );
};

export default EssentialParts;
