import { useEffect, useState } from 'react';
import fs from 'fs';
import { useIntl } from 'react-intl';
import { profitAndLossExpectedHeadersAndData } from '../helpers';
import { generateToastr } from '../../../core/toastMessages';
import ProgressBar from '../../../core/components/ProgressBar';

interface FileUploadProps {
  siteLabel: string;
  siteId: number;
  allowedFiletypes: string[];
  selectedFiles: number[];
  displayFiletypes?: boolean;
  fileAddedCallback: any;
  inputId: string;
  isLoading: boolean;
}

const SiteFileUpload = ({
  siteLabel,
  siteId,
  allowedFiletypes,
  displayFiletypes = false,
  fileAddedCallback,
  inputId,
  isLoading,
  selectedFiles,
}: FileUploadProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFilename] = useState('');
  const [isDragging, setDragging] = useState(false);
  const [hasError, setHasError] = useState(false);
  const fieldId = `file_upload_siteId_${inputId}`;
  const fileTypes = allowedFiletypes.map((type) => `.${type},`).join('');
  const intl = useIntl();
  const reader = new FileReader();

  const validFileType = (filename: string) => {
    const extension = filename.split('.').pop() || '';
    if (!allowedFiletypes.includes(extension)) {
      generateToastr('error', intl.formatMessage({ id: 'invalidFileType' }), 'Please use CSV', {
        timeOut: 10000,
      });

      return false;
    }
    return allowedFiletypes.includes(extension);
  };

  const modifyFile = (oldFile: File) => {
    const newFile = new File([oldFile], `${siteId}-${oldFile.name}`, {
      type: oldFile.type,
      lastModified: oldFile.lastModified,
    });

    return newFile;
  };

  const validateFileColumns = (content: File | null) => {
    if (content) {
      reader.readAsText(content);
      reader.onload = () => {
        if (!reader.result) {
          setHasError(true);
          return;
        }
        const rows = reader.result?.toString().split('\n');
        let headers = rows?.map((row) => row.split(',')[0]);
        // remove an /r from the end of the all the headers - seems to show up after an edit
        headers = headers.map((header) => header.replace(/\r/g, ''));
        const missingHeaders = profitAndLossExpectedHeadersAndData.headers.filter(
          (header) => !!headers && !headers.includes(header)
        );
        if (missingHeaders.length > 0) {
          setHasError(true);
          setFile(null);
          generateToastr('error', intl.formatMessage({ id: 'missingHeaders' }), ...missingHeaders, {
            timeOut: 10000,
          });
        } else {
          const modifiedContent = modifyFile(content);
          setFile(modifiedContent);
        }
      };
    }
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files?.length) {
      if (validFileType(e.target.files[0]?.name)) {
        validateFileColumns(e.target.files[0]);
      } else {
        e.target.value = null;
        setHasError(true);
      }
    }
  };
  const handleDragOver = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setDragging(true);
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setDragging(false);
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      if (validFileType(droppedFiles[0]?.name)) {
        validateFileColumns(droppedFiles[0]);
      } else {
        setHasError(true);
      }
    }
    setDragging(false);
  };

  useEffect(() => {
    if (file) {
      setFilename(file.name);
      fileAddedCallback({ siteId, file });
    }
  }, [file]);

  return isLoading && selectedFiles.includes(siteId) ? (
    <div className="form-group file-upload has-file loading">
      <p className="label">{siteLabel}</p>
      <ProgressBar />
    </div>
  ) : (
    <div
      className={`form-group file-upload${file ? ' has-file' : ''}${isDragging ? ' dragging' : ''}${
        hasError ? ' error' : ''
      }`}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      onAnimationEnd={() => setHasError(false)}
    >
      <p className="label">
        <i className={`uil uil-check-circle form-checkmark${file ? ' visible' : ''}`}></i>
        <span>
          {siteLabel}
          {fileName && <span className="filename"> ({fileName})</span>}
        </span>
      </p>
      <div className="file-picker">
        {displayFiletypes && (
          <p className="filetypes">{fileTypes.substring(0, fileTypes.length - 1)}</p>
        )}
        <label htmlFor={fieldId}>
          <input
            type="file"
            id={fieldId}
            name={fieldId}
            accept={fileTypes}
            onChange={handleChange}
          />
          {file
            ? intl.formatMessage({ id: 'updateFile' })
            : intl.formatMessage({ id: 'chooseFile' })}
        </label>
      </div>
    </div>
  );
};

export default SiteFileUpload;
