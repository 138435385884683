export const generalMessages = {
  en: {
    exportToCsv: 'Export to CSV',
    description: 'Description',
    startDate: 'Start',
    endDate: 'End',
    creator: 'Creator',
    createdAt: 'Created',
    name: 'Name',
    action: 'Actions',
    showAll: 'Show All',
    price: 'Price',
    all: 'All',
    firstName: 'First Name',
    lastName: 'Last Name',
    type: 'Type',
    cost: 'Cost',
    amount: 'Amount',
    date: 'Date',
    email: 'Email',
    phone: 'Phone',
    phoneNumber: ' Phone Number',
    position: 'Position',
    plate: 'Plate',
    make: 'Make',
    model: 'Model',
    year: 'Year',
    color: 'Color',
    vin: 'VIN',
    upload: 'Upload',
    image: 'Image',
    video: 'Video',
    select: 'Select',
    uploadFile: 'Upload file',
    uploading: 'Uploading',
    file: 'File',
    issue: 'Issue',
    issueDate: 'Issue date',
    issueCreated: 'Issue created',
    back: 'Back',
    cancel: 'Cancel',
    ok: 'Ok',
    All: 'All',
    vehicle: 'Vehicle',
    location: 'Location',
    photos: 'Photos',
    loading: 'Loading...',
    loadingSites: 'Loading sites',
    videos: 'Videos',
    customerInfo: 'Customer Info',
    vehicleInfo: 'Vehicle Info',
    edit: 'Edit',
    delete: 'Delete',
    archive: 'Archive',
    unarchive: 'Unarchive',
    Denied: 'Denied',
    Completed: 'Completed',
    accept: 'Accept',
    unknown: 'Unknown',
    send: 'Send',
    decline: 'Decline',
    start: 'Start',
    goBack: 'Go Back',
    save: 'Save',
    end: 'End',
    noImage: 'No image available',
    notAvailable: 'N/A',
    expired: 'Expired',
    due: 'Due',
    id: 'Id',
    url: 'URL',
    item: 'Item',
    Other: 'Other',
    search: 'Search...',
    site: 'Site',
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This week',
    lastWeek: 'Last week',
    thisMonth: 'This month',
    lastMonth: 'Last month',
    yearToDate: 'Year to date',
    custom: 'Custom',
    units: 'Units',
    field: 'Field',
    oldValue: 'Old Value',
    newValue: 'New Value',
    noHistory: 'No history',
  },
};

export default generalMessages;
