import { invert as _invert } from 'lodash';
import { toEnum, toString } from '../../core/utils';
import { UtilityType } from './models';

/* eslint-disable import/prefer-default-export */
export const utilityHelper = {
  ensureCurrentUnitExists: (units, typeKey, unitKey) => {
    const checkedUnits = { ...units };
    const availableUnits = { ...checkedUnits[typeKey] };
    if (!availableUnits[unitKey]) {
      availableUnits[unitKey] = Object.keys(availableUnits).length;
      checkedUnits[typeKey] = availableUnits;
    }
    return checkedUnits;
  },
  utilityTypeEnumKeyFromVal: (typeVal) => {
    return toString(UtilityType, typeVal);
  },

  utilityTypeEnumValFromKey: (typeKey) => {
    return toEnum(UtilityType, typeKey);
  },

  utilityUnitEnumKeyFromVal: (unitVal, typeKey, utilityUnits) => {
    if (Number.isNaN(Number(unitVal))) {
      return unitVal;
    }
    const currentUnit = utilityUnits[typeKey];
    return _invert(currentUnit)[unitVal];
  },

  utilityUnitEnumValFromKey: (unitKey, typeKey, utilityUnits) => {
    if (!Number.isNaN(Number(unitKey))) {
      return unitKey;
    }
    return utilityUnits[typeKey][unitKey];
  },
};

export const profitAndLossExpectedHeadersAndData = {
  headers: [
    'Statement Period',
    'Income',
    'Income:Car Wash Revenue',
    'Income:Car Wash Revenue:Car Washes',
    'Income:Car Wash Revenue: Monthly Wash Club Sales',
    'Income:Merchandise Sales',
    'Income:Merchandise Sales:Air Fresheners',
    'Income:Merchandise Sales:Detail Kits',
    'Income:Merchandise Sales:Vending Machine Sales',
    'Income:Uncategorized Income',
    'Income:Gift Cards Redemmed',
    'Interest',
    'Tax',
    'Cost of Goods Sold',
    'Cost of Goods Sold:Cost of Goods Sold.SUB',
    'Cost of Goods Sold:Cost of Goods Sold.SUB:COGS - Detergents',
    'Cost of Goods Sold:Cost of Goods Sold.SUB - Credi Card Fees',
    'Cost of Goods Sold:Cost of Goods Sold.SUB:COGS-Royalty Fees',
    'Cost of Goods Sold:Cost of Goods Sold.SUB:COGS-Merchandise',
    'Cost of Goods Sold:Cost of Goods Sold.SUB:COGS - Other',
    'Cost of Goods Sold:Cost of Goods Sold.SUB:COGS - Gift Cards',
    'Expense',
    'Expense:Operating Expenses',
    'Expense:Operating Expenses:Customer Claims',
    'Expense:Operating Expenses:General Administrative',
    'Expense:Operating Expenses:General Administrative:Professional Fees',
    'Expense:Operating Expenses:General Administrative:Technology Costs',
    'Expense:Operating Expenses:General Administrative:Bank Charges',
    'Expense:Operating Expenses:General Administrative:Cash Over/short',
    'Expense:Operating Expenses:General Administrative:Other G & A',
    'Expense:Operating Expenses:Labor',
    'Expense:Operating Expenses:Labor:Team Member Wages',
    'Expense:Operating Expenses:Labor:Subcontracted Wages',
    'Expense:Operating Expenses:Labor:Payfoll taxes',
    'Expense:Operating Expenses:Labor:Benefits',
    'Expense:Operating Expenses:Labor:Benefits:Workers Compensation',
    'Expense:Operating Expenses:Labor:Benefits:Health Insurance',
    'Expense:Operating Expenses:Labor:Benefits:Team Member Uniforms',
    'Expense:Operating Expenses:Labor:Recruiting/hiring costs',
    'Expense:Operating Expenses:Labor:Other - Labor',
    'Expense:Operating ExpensesLabor::Training Wages',
    'Expense:Operating Expenses:Marketing',
    'Expense:Operating Expenses:Marketing:Brand Development',
    'Expense:Operating Expenses:Marketing:Advertising/Marketing',
    'Expense:Operating Expenses:Occupancy',
    'Expense:Operating Expenses:Occupancy:Rent',
    'Expense:Operating Expenses:Occupancy:Utilities',
    'Expense:Operating Expenses:Occupancy:Utilities:Waste Removal',
    'Expense:Operating Expenses:Occupancy:Utilities:Communications',
    'Expense:Operating Expenses:Occupancy:Utilities:Gas',
    'Expense:Operating Expenses:Occupancy:Utilities:Sewer',
    'Expense:Operating Expenses:Occupancy:Utilities:Water',
    'Expense:Operating Expenses:Occupancy:Utilities:Electric',
    'Expense:Operating Expenses:Occupancy:P&C Insurance',
    'Expense:Operating Expenses:Occupancy:Repairs and Maintenance',
    'Expense:Operating Expenses:Occupancy:Property Tax',
    'Expense:Operating Expenses:Occupancy:Permits and Licenses',
    'Expense:Operating Expenses:Other Business Expenses',
    'Expense:Operating Expenses:Other Business Expenses:Office Supplies',
    'Expense:Operating Expenses:Other Business Expenses:Dues and Subscriptions',
    'Expense:Operating Expenses:Other Business Expenses:Charitable Contributions',
    'Expense:Operating Expenses:Training',
    'Expense:Operating Expenses:Training:Training Transportation',
    'Expense:Operating Expenses:Training:Lodging',
    'Expense:Operating Expenses:Training:Meals',
    'Expense:Operating Expenses:Training:Corporate training fees',
    'Expense:Operating Expenses:Training:Other - Training',
    'Expense:Operating Expenses:Transportation',
    'Expense:Operating Expenses:Transportation:Vechicle Insurance',
    'Expense:Operating Expenses:Transportation: Vehicle repairs and maintenance',
    'Expense:Operating Expenses:Transportation:Other - Transportation',
    'Expense:Operating Expenses:Other',
    'Expense:Operating Expenses:Other:Interest',
    'Expense:Operating Expenses:Other:Depreciation',
    'Expense:Operating Expenses:Other:Amortization',
  ],
  data: [
    'YYYY-MM',
    '',
    '',
    '$0.00',
    '$0.00',
    '',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '',
    '',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '',
    '',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '',
    '$0.00',
    '$0.00',
    '$0.00',
    '',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '',
    '$0.00',
    '$0.00',
    '',
    '$0.00',
    '',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '',
    '$0.00',
    '$0.00',
    '$0.00',
    '',
    '$0.00',
    '$0.00',
    '$0.00',
    '',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '$0.00',
    '',
    '$0.00',
    '$0.00',
    '$0.00',
  ],
};

export const downloadProfitAndLossCSV = () => {
  // Convert array to CSV content where the first column are the headers and the second column is the data
  const { headers, data } = profitAndLossExpectedHeadersAndData;
  // Create an array of rows where each row is an array of [header, data]
  const rows = headers.map((header, index) => [header, data[index]]);
  // Convert rows to CSV string
  const csvContent = rows.map((row) => row.join(',')).join('\n');

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'data.csv');

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the download by simulating a click
  link.click();

  // Remove the link after download
  document.body.removeChild(link);
};
