import { Inventory } from './models';
import moment from 'moment-timezone';

const from = sessionStorage.getItem('inventoryFrom');
const until = sessionStorage.getItem('inventoryUntil');

const tz = sessionStorage.getItem('timeZone');

const fromDate = moment.tz(from, tz).startOf('day');
const untilDate = moment.tz(until, tz).endOf('day');

const initialState = {
  inventory: [],
  inventoryInEdit: {},
  inventoryData: [],
  inventoryTypes: [],
  fromDate: from ? fromDate : moment.tz(tz).subtract(10, 'days'),
  untilDate: until ? untilDate : moment.tz(tz).endOf('day'),
  typeFilter: 0,
  stockUpdateStatus: '',
};

const fetchInventorySuccess = (state, response) => {
  const inventory = response.results.map((i) => new Inventory(i));
  return {
    ...state,
    inventory,
    page: response.page,
    total: response.total,
    pageSize: response.pageSize,
  };
};

const fetchInventoryTypesSuccess = (state, response) => {
  return {
    ...state,
    inventoryTypes: response.results,
  };
};

const fetchDetergentDataSuccess = (state, response) => {
  return {
    ...state,
    inventoryData: response.results,
  };
};

const getInventoryItemSuccess = (state, inventory) => {
  return {
    ...state,
    inventoryInEdit: new Inventory(inventory),
  };
};

const deleteInventoryInEdit = (state) => {
  return {
    ...state,
    inventoryInEdit: {},
  };
};

const clearInventoryData = (state) => {
  return {
    ...state,
    inventoryData: [],
  };
};

const setFromDate = (state, date) => {
  window.sessionStorage.setItem('inventoryFrom', date.format('YYYY-MM-DD'));
  return {
    ...state,
    fromDate: date,
  };
};

const setUntilDate = (state, date) => {
  window.sessionStorage.setItem('inventoryUntil', date.format('YYYY-MM-DD'));
  return {
    ...state,
    untilDate: date,
  };
};

const setTypeFilter = (state, typeFilter) => {
  return {
    ...state,
    typeFilter,
  };
};

const setInventoryStockStatus = (state, status) => {
  return {
    ...state,
    stockUpdateStatus: status,
  };
};

export const inventory = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_INVENTORY_SUCCESS':
      return fetchInventorySuccess(state, action.response);
    case 'FETCH_INVENTORY_TYPES_SUCCESS':
      return fetchInventoryTypesSuccess(state, action.response);
    case 'FETCH_DETERGENT_DATA_SUCCESS':
      return fetchDetergentDataSuccess(state, action.response);
    case 'UPDATE_INVENTORY_STOCK_SUCCESS':
      return setInventoryStockStatus(state, 'success');
    case 'UPDATE_INVENTORY_STOCK_FAILURE':
      return setInventoryStockStatus(state, 'error');
    case 'GET_INVENTORY_ITEM_SUCCESS':
      return getInventoryItemSuccess(state, action.response);
    case 'DELETE_INVENTORY_IN_EDIT':
      return deleteInventoryInEdit(state);
    case 'CLEAR_INVENTORY_DATA':
      return clearInventoryData(state);
    case 'SET_INVENTORY_FROM_DATE':
      return setFromDate(state, action.date);
    case 'SET_INVENTORY_UNTIL_DATE':
      return setUntilDate(state, action.date);
    case 'SET_INVENTORY_TYPE_FILTER':
      return setTypeFilter(state, action.typeFilter);
    case 'SET_STOCK_UPDATE_STATUS':
      return setInventoryStockStatus(state, status);
    default:
      return state;
  }
};

export const selectInventory = (state) => {
  return state.inventory.inventory;
};

export const selectTypeFilter = (state) => {
  return state.inventory.typeFilter;
};

export const selectInventoryTypes = (state) => {
  return state.inventory.inventoryTypes;
};

export const selectInventoryInEdit = (state) => {
  return state.inventory.inventoryInEdit;
};

export const selectTotal = (state) => {
  return state.inventory.total;
};

export const selectPage = (state) => {
  return state.inventory.page;
};

export const selectPageSize = (state) => {
  return state.inventory.pageSize;
};

export const selectInventoryData = (state) => {
  return state.inventory.inventoryData;
};

export const selectFromDate = (state) => {
  return state.inventory.fromDate;
};

export const selectUntilDate = (state) => {
  return state.inventory.untilDate;
};

export const selectStockUpdateStatus = (state) => {
  return state.inventory.stockUpdateStatus;
};

export const selectEssentialPage = (state) => {
  return state.inventory?.essentialParts?.page ?? selectPage(state);
};

export const selectEssentialPageSize = (state) => {
  return state.inventory?.essentialParts?.pageSize ?? selectPageSize(state);
};

export const selectEssentialParts = (state) => {
  return state.inventory?.essentialParts?.parts ?? selectInventory(state);
};

export const selectEssentialTotal = (state) => {
  return state.inventory?.essentialParts?.total ?? selectTotal(state);
};

export const selectEssentialPartsInEdit = (state) => {
  return state.inventory?.essentialPartsInEdit ?? selectInventoryInEdit(state);
};
