import { selectEmployees } from '../../employees/reducer';
import EmployeeDropdown from '../../../core/components/EmployeeDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { fetchEmployees } from '../../employees/actions';
import PAndLUploadForm from '../components/PAndLUploadForm';
import VendingSalesForm from '../components/VendingSalesForm';
import { selectAuthor, selectForm } from '../uploadsReducer';
import { setUploadsAuthor, setUploadsFormSelection } from '../actions';
import { selectSitesBasic } from '../../site-selection/reducer';

const Uploads = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const employees = useSelector(selectEmployees);
  const selectedEmployee = useSelector(selectAuthor);
  const selectedForm = useSelector(selectForm);
  const sites = useSelector(selectSitesBasic);
  const [formKey, setFormKey] = useState(Date.now());
  const [sortedSites, setSortedSites] = useState([]);

  const forms = [
    {
      slug: 'pl_upload',
      title: 'P & L Upload',
    },
    {
      slug: 'vending_sales',
      title: 'Vending Sales Upload',
    },
  ];

  const [visibleForm, setVisibleForm] = useState(forms[0]);

  useEffect(() => {
    dispatch(fetchEmployees());
  }, []);

  useEffect(() => {
    if (selectedEmployee == 0 && employees.length) {
      dispatch(setUploadsAuthor(Number(employees[0].id)));
    }
  }, [employees]);

  useEffect(() => {
    setVisibleForm(forms.find((f: any) => f.slug == selectedForm) || forms[0]);
  }, [selectedForm]);

  useEffect(() => {
    const newSorted = sites.sort((a: any, b: any) => {
      //Sort by the displayed site label, either ID or name
      const labelA = a.externalId ? a.externalId : a.name;
      const labelB = b.externalId ? b.externalId : b.name;
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
    setSortedSites(newSorted);
  }, [sites]);

  const handleFormSelect = (e: any) => {
    dispatch(setUploadsFormSelection(e.target.value));
  };

  const handleSetEmployee = (e: any) => {
    dispatch(setUploadsAuthor(Number(e)));
  };

  const triggerReset = () => {
    setFormKey(Date.now());
  };

  return (
    <div className="expenses-upload">
      <div className="table-filters">
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'chooseForm' })}</label>
          <select className="form-control" value={selectedForm} onChange={handleFormSelect}>
            {forms.map((form: any) => (
              <option key={form.slug} value={form.slug}>
                {form.title}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'chooseAuthor' })}</label>
          <EmployeeDropdown
            employees={employees}
            currentEmployeeId={selectedEmployee}
            setEmployee={handleSetEmployee}
            intl={intl}
          />
        </div>
      </div>
      <div className="selected-form">
        {visibleForm.slug == 'pl_upload' && (
          <PAndLUploadForm
            employeeId={selectedEmployee}
            sites={sortedSites}
            intl={intl}
            formReset={triggerReset}
            key={`${forms[0].slug}_${formKey}`}
          />
        )}
        {visibleForm.slug == 'vending_sales' && (
          <VendingSalesForm
            employeeId={selectedEmployee}
            sites={sortedSites}
            intl={intl}
            formReset={triggerReset}
            key={`${forms[1].slug}_${formKey}`}
          />
        )}
      </div>
    </div>
  );
};

export default Uploads;
