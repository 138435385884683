import '../../../styles/login.scss';
import ForgotPasswordForm from './ForgotPasswordForm';
import LandingPageFooter from './LandingPageFooter';
import LandingPageHeader from './LandingPageHeader';

const ForgotPassword = () => {
  const isWashClub = window.location.hostname === 'portal.wash.club';
  const backgroundClass = isWashClub ? 'wash-club-login-container' : 'login-container';

  return (
    <div className={`container-fluid ${backgroundClass}`}>
      <div className="login-sidebar">
        <LandingPageHeader />
        <ForgotPasswordForm />
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default ForgotPassword;
