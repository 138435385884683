import * as storage from '../../core/storage';
import { API_REQUEST } from '../../core/actions';
import * as alertsAPI from '../../core/api/alerts';

export const FETCH_ALERTS = 'FETCH_ALERTS';
export const ACKNOWLEDGE_ALERT = 'ACKNOWLEDGE_ALERT';
export const SET_SELECTED_SITE = 'SET_SELECTED_SITE';
export const CLEAR_SITE_SELECTIONS = 'CLEAR_SITE_SELECTIONS';
export const SET_TIMEZONE = 'SET_TIMEZONE';
export const SET_MULTI_SITE_SELECTION = 'SET_MULTI_SITE_SELECTION';
export const SET_PRIMARY_SITE = 'SET_PRIMARY_SITE';

export const fetchAlerts = (pageSize = 50) => ({
  type: API_REQUEST,
  callAPI: alertsAPI.fetchAlerts,
  data: { query: { pageSize } },
  action: FETCH_ALERTS,
  meta: {
    useManagerSiteId: 'true',
    errorApi: 'fetchAlerts',
    errorMessage: 'Failed to fetch alerts',
  },
});

export const acknowledgeAlert = (id) => ({
  type: API_REQUEST,
  callAPI: alertsAPI.acknowledgeAlert,
  data: { id },
  action: ACKNOWLEDGE_ALERT,
});

export const setSelectedSite = ({ id }) => ({ type: SET_SELECTED_SITE, id });

export const setMultiSiteSelection = (sites) => ({ type: SET_MULTI_SITE_SELECTION, sites });

export const updateTimeZone = ({ settings }) => {
  if (settings && settings.site && settings.site.timeZone) {
    sessionStorage.setItem('timeZone', settings.site.timeZone);
  }

  return { type: SET_TIMEZONE };
};

export const setAndStoreSelectedSite = ({ id }) => {
  storage.setSelectedSite(id);
  return { type: SET_SELECTED_SITE, id };
};
export const clearSiteSelections = () => ({ type: CLEAR_SITE_SELECTIONS });

export const setPrimarySite = (site) => ({ type: 'SET_PRIMARY_SITE', response: site });
