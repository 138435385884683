import { get, isNil } from 'lodash';
import moment from 'moment';
import { all, call, put, select, take } from 'redux-saga/effects';
import { selectOperatorGroupId, selectRole, selectSite } from '../../store/authReducer';
import { refreshRequest, REFRESH_SUCCESS } from '../../views/login/actions';
import { selectSiteGroupId } from '../../views/settings/reducer';
import { hasRole, RoleTypes } from '../components/Permission';
import { TOKEN_HEADER } from '../constants';
import QueueAction from '../queueAction';
import { getJwtToken, getRefreshToken, getSelectedSite } from '../storage';
import * as toastMessages from '../toastMessages';

export function* notifyNetworkError(error, meta) {
  if (meta && meta?.errorApi) {
    yield call(toastMessages.handledApiError, meta.errorMessage);
  } else {
    yield call(toastMessages.apiError, error);
  }
}

function* handleUnauthorized({ fn, data, options }, tokens, meta) {
  yield put(refreshRequest(tokens));

  yield take(REFRESH_SUCCESS);

  const newOptions = {
    ...options,
    headers: {
      [TOKEN_HEADER]: yield call(getJwtToken),
    },
  };

  try {
    return yield call(fn, { data, options: newOptions });
  } catch (error) {
    return yield notifyNetworkError(error, meta);
  }
}

// wrapper for api calls which handles the errors and other common concerns
export default function* apiRequest(fn, data, args) {
  const jwtToken = yield call(getJwtToken);
  const refreshToken = yield call(getRefreshToken);
  const siteId = yield select(selectSite);
  const meta = getMetaData(args);

  let groupId = yield select(selectOperatorGroupId);

  if (!groupId) {
    const role = yield select(selectRole);

    if (hasRole(role, RoleTypes.Admin)) {
      groupId = yield select(selectSiteGroupId);
    }
  }

  const options = {
    headers: {
      [TOKEN_HEADER]: jwtToken,
    },
    siteId,
    groupId,
  };

  const apiRequestProperties = { fn, data, options };

  try {
    return yield call(fn, { data, options });
  } catch (error) {
    if (error.statusCode === 401) {
      return yield handleUnauthorized(apiRequestProperties, { jwtToken, refreshToken }, meta);
    }

    if (error.statusCode) {
      yield notifyNetworkError(error, meta);
    }

    throw error;
  }
}

function getMetaData(args = {}) {
  const queueAction = get(args, 'meta.queueAction');

  let meta = args.meta;

  if (!isNil(queueAction)) {
    meta = {
      ...meta,
      queueAction: QueueAction.RemoveFromQueue,
    };
  }

  return meta;
}

export function getRange(startDate, endDate, type) {
  let fromDate = moment(startDate);
  let toDate = moment(endDate);
  return toDate.diff(fromDate, type);
}

// don't have to call this if need for special handling,
// just call apiRequest directly and handle errors normally in saga
export function* apiSaga(fn, data, action, args) {
  try {
    yield put({ type: `${action}_REQUEST` });

    const response = yield call(apiRequest, fn, data, args);

    const meta = getMetaData(args);

    yield put({ type: `${action}_SUCCESS`, response, meta });

    if (args && args.closeModal) {
      yield put({ type: 'HIDE_DIALOG' });
    }

    if (args && args.navigate) {
      yield call(args.navigate, response);
    }

    if (args && args.callback) {
      yield !Array.isArray(args.callback)
        ? put(args.callback())
        : all(
            // eslint-disable-next-line
            args.callback.map(function (cb) {
              return put(cb());
            })
          );
    }

    if (args && args.voidCallback) {
      yield call(args.voidCallback);
    }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line
      // console.error(error);
    }

    const meta = getMetaData(args);

    if (meta && meta?.errorApi) {
      if (meta?.errorApi === 'fetchSite') {
        meta.navigate();
      }
    }

    yield put({ type: `${action}_FAILURE`, error, meta });
  }
}
