import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps, useHistory } from 'react-router';
import { Col, Form, Label, Row } from 'reactstrap';
import ButtonWithLoader from '../../../core/components/ButtonWithLoader';
import { requiredInput as RequiredInput } from '../../../core/formValidation';
import '../../../styles/set-password-form.scss';
import useSetPasswordForm from '../hooks/useSetPasswordForm';

interface MatchParams {
  token: string;
}

interface Props extends RouteComponentProps<MatchParams> {} //eslint-disable-line

const SetPasswordForm = ({ match }: Props): JSX.Element => {
  const {
    formik,
    handleSubmitClick,
    statusMessage,
    removeStatusMessage,
    setErrorMessage,
    checkValidationToken,
  } = useSetPasswordForm();
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const history = useHistory();
  const formDisabled = !formik.isValid || formik.isSubmitting || passwordsMatch;
  const intl = useIntl();

  const getTextInput = (
    fieldName: string,
    placeholderId: string,
    type: string,
    disabled: boolean = false // eslint-disable-line
  ) => {
    return {
      id: fieldName,
      type,
      className: 'set-password-form-input',
      placeholder: intl.formatMessage({ id: placeholderId }),
      ...formik.getFieldProps(fieldName),
      disabled,
    };
  };

  useEffect(() => {
    const { token } = match.params;
    checkValidationToken(token);
  }, []);
  useEffect(() => {
    if (statusMessage.message === 'passwordUpdated') {
      setTimeout(() => {
        history.push('/login');
        removeStatusMessage();
      }, 2000);
    }
  }, [statusMessage.message]);
  useEffect(() => {
    const password = formik.getFieldProps('password').value;
    const confirmPassword = formik.getFieldProps('confirmPassword').value;
    if (confirmPassword) {
      if (password === confirmPassword) {
        if (password.length < 6) {
          setPasswordsMatch(true);
          setErrorMessage('passwordLength');
        }
        if (password.length >= 6) {
          setPasswordsMatch(false);
          return removeStatusMessage();
        }
      }
      if (password !== confirmPassword) {
        setPasswordsMatch(true);
        setErrorMessage('passwordMustMatch');
      }
    }
  }, [formik.getFieldProps('confirmPassword').value, formik.getFieldProps('password').value]);

  return statusMessage.message === 'Token is invalid' ? (
    <div className="set-password-error alert alert-danger">
      {intl.formatMessage({ id: 'invalidTokenMessage' })}
    </div>
  ) : (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="form-signin"
      data-testid="set-password-form"
    >
      <Row>
        <Col>
          <Label for="password" className="set-password-form-input-label">
            {intl.formatMessage({ id: 'password' })}
          </Label>
          <RequiredInput input={getTextInput('password', 'password', 'password')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Label for="confirmPassword" className="set-password-form-input-label">
            {intl.formatMessage({ id: 'confirmPassword' })}
          </Label>
          <RequiredInput input={getTextInput('confirmPassword', 'confirmPassword', 'password')} />
        </Col>
      </Row>
      {statusMessage && statusMessage.type === 'success' && (
        <div className="set-password-error alert alert-success">
          {intl.formatMessage({ id: statusMessage.message })}
        </div>
      )}
      {statusMessage && statusMessage.type === 'failure' && (
        <div className="set-password-error alert alert-danger">
          {intl.formatMessage({ id: statusMessage.message })}
        </div>
      )}
      {statusMessage && statusMessage.type !== 'success' && (
        <ButtonWithLoader
          handleClick={handleSubmitClick}
          disabled={formDisabled}
          isLoading={formik.isSubmitting}
        >
          <div className="set-password-button-text">{intl.formatMessage({ id: 'send' })}</div>
        </ButtonWithLoader>
      )}
    </Form>
  );
};

export default SetPasswordForm;
