import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Col, Form, Label, Row } from 'reactstrap';
import ButtonWithLoader from '../../../core/components/ButtonWithLoader';
import { requiredInput as RequiredInput } from '../../../core/formValidation';
import '../../../styles/forgot-password-form.scss';
import { IntlType } from '../../../utils/typing';
import useForgotPasswordForm from '../hooks/useForgotPasswordForm';

interface Props {
  intl: IntlType;
}

const ForgotPasswordForm = (props: Props): JSX.Element => {
  const { intl } = props;
  const history = useHistory();
  const { formik, handleSubmitClick, statusMessage, removeErrorMessage } = useForgotPasswordForm();

  useEffect(() => {
    if (statusMessage.type === 'success') {
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    }
  }, [statusMessage.type]);
  const handleBackButton = () => {
    history.push('/login');
    removeErrorMessage();
  };
  const getTextInput = (fieldName: string, placeholderId: string, type: string) => {
    return {
      id: fieldName,
      type,
      className: 'forgot-password-form-input',
      placeholder: intl.formatMessage({ id: placeholderId }),
      ...formik.getFieldProps(fieldName),
    };
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="form-signin"
      data-testid="forgot-password-form"
    >
      <Row>
        <Col>
          <Label for="username" className="forgot-password-input-label">
            {intl.formatMessage({ id: 'emailAddress' })}
          </Label>
          <RequiredInput input={getTextInput('username', 'emailAddress', 'text')} />
        </Col>
      </Row>
      {statusMessage && statusMessage.type === 'success' && (
        <div className="forgot-password-error alert alert-success">
          {intl.formatMessage({ id: statusMessage.message })}
        </div>
      )}
      <ButtonWithLoader handleClick={handleSubmitClick}>
        <div className="forgot-password-button-text">{intl.formatMessage({ id: 'sendCode' })}</div>
      </ButtonWithLoader>
      {statusMessage && statusMessage.type === 'failure' && (
        <div className="forgot-password-error alert alert-danger">
          {intl.formatMessage({ id: statusMessage.message })}
        </div>
      )}
      <div className="forgot-password-back" onClick={handleBackButton}>
        {intl.formatMessage({ id: 'backToSignIn' })}{' '}
      </div>
    </Form>
  );
};

export default injectIntl(ForgotPasswordForm);
