import moment from 'moment';
import { InventoryHistory } from '../models';
import DataTable from '../../../core/components/DataTable';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { sortBy } from 'lodash';

interface Props {
  history: InventoryHistory[];
}

const InventoryHistoryTab = (props: Props): JSX.Element => {
  const intl = useIntl();
  const loadDataforTable = (history: InventoryHistory[]) => {
    const data = history.map((item) => {
      return {
        _id: item.id,
        time: moment(item.createdAt).format('DD/MM/YYYY hh:mm a'),
        field: item.property,
        oldValue: item.oldValue,
        newValue: item.newValue,
      };
    });

    const headers = [
      { name: intl.formatMessage({ id: 'time' }), key: 'createdAt', databaseProperty: 'createdAt' },
      { name: intl.formatMessage({ id: 'field' }), key: 'name', databaseProperty: 'property' },
      {
        name: intl.formatMessage({ id: 'oldValue' }),
        key: 'oldValue',
        databaseProperty: 'oldValue',
      },
      {
        name: intl.formatMessage({ id: 'newValue' }),
        key: 'newValue',
        databaseProperty: 'newValue',
      },
    ];

    return {
      data: sortBy(data, 'time', 'desc'), // Sort by 'time' in descending order
      headers,
      scrolling: true,
      searchOptions: {
        uniqueField: '_id',
        dataToSearchFor: ['field', 'oldValue', 'newValue'],
      },
    };
  };

  useEffect(() => {
    loadDataforTable(props.history);
  }, [props.history]);

  return props.history?.length ? (
    <div data-testid="inventory-history-table">
      <DataTable {...loadDataforTable(props.history)} />
    </div>
  ) : (
    <div data-testid="inventory-no-history">{intl.formatMessage({ id: 'noHistory' })}</div>
  );
};

export default InventoryHistoryTab;
