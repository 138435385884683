import * as api from '../api';

export const fetchAlerts = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/alarms`, args);
};

// TODO: refactor url to alerts once changed on the backend
export const acknowledgeAlert = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/alarms/${id}/acknowledge`, args);
};
