import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectModal } from '../../../store/modal';
import DeclineIssueDialog from '../../../views/claimDetail/containers/DeclineIssueDialog';
import RepairDataDialog from '../../../views/claimDetail/containers/RepairDataDialog';
import UpsertFileDialog from '../../../views/claimDetail/containers/UpsertFileDialog';
import AddDetergentDialog from '../../../views/detergents/containers/AddDetergentDialog';
import AddDetergentReadingDialog from '../../../views/detergents/containers/AddDetergentReadingDialog';
import EditDetergentDialog from '../../../views/detergents/containers/EditDetergentDialog';
import UpsertEmployeesDialog from '../../../views/employees/containers/UpsertEmployeeDialog';
import AddVendorDialog from '../../../views/expenses/containers/AddVendorDialog';
import PurchaseOrderDialog from '../../../views/expenses/containers/PurchaseOrderDialog';
import UpsertUtilitiesDialog from '../../../views/expenses/containers/UpsertUtilitiesDialog';
import UpsertFeedbackDialog from '../../../views/feedback/containers/UpsertFeedbackDialog';
import UpsertDetergentDataDialog from '../../../views/inventory/containers/UpsertDetergentDataDialog';
import UpsertInventoryDialog from '../../../views/inventory/containers/UpsertInventoryDialog';
import DeleteInventoryDialog from '../../../views/inventory/containers/DeleteInventoryDialog';
import CreateUserDialog from '../../../views/settings/components/CreateUserDialog';
import EditUserDialog from '../../../views/settings/components/EditUserDialog';
import UpsertProductDialog from '../../../views/settings/components/UpsertProductDialog';
import UpsertWashCounterDialog from '../../../views/settings/components/UpsertWashCounterDialog';
import UpsertShiftDialog from '../../../views/shifts/containers/UpsertShiftDialog';
import CompleteTaskAOTDialog from '../../../views/tasks/containers/CompleteTaskAOTDialog';
import CompleteTaskDialog from '../../../views/tasks/containers/CompleteTaskDialog';
import TodaysTasksDialog from '../../../views/tasks/containers/TodaysTasksDialog';
import UpsertTaskDialog from '../../../views/tasks/containers/UpsertTaskDialog';
import UpsertProjectDialog from '../../../views/maintenanceTasks/components/UpsertProjectDialog';
import AddWashCountDialog from '../../../views/wash-counts/containers/AddWashCountsDialog';
import AdjustmentDetailDialog from '../../../views/wash-counts/containers/AdjustmentDetailDialog';
import AdjustPOSCountDialog from '../../../views/wash-counts/containers/AdjustPOSCountDialog';
import CloseWorkLogDialog from '../../../views/work-logs/containers/CloseWorkLogDialog';
import OpenWorkLogDialog from '../../../views/work-logs/containers/OpenWorkLogDialog';
import ConfirmDialog from '../ConfirmDialog';
import DependencyWarningDialog from '../../../views/maintenanceTasks/components/DependencyWarningDialog';
import UnlinkTaskDialog from '../../../views/maintenanceTasks/components/UnlinkTaskDialog';

import NoteDialog from '../NoteDialog';
import TaskLinkingModalDialog from '../../../views/maintenanceTasks/components/TaskLinkingModalDialog';
import { omit } from 'lodash';

export type ModalType =
  | 'TODAYS_TASKS_DIALOG'
  | 'UPSERT_TASK'
  | 'UPSERT_PROJECT'
  | 'COMPLETE_TASK'
  | 'COMPLETE_TASK_AOT'
  | 'ADD_DETERGENT'
  | 'ADD_DETERGENT_READING'
  | 'EDIT_DETERGENT'
  | 'CONFIRM_DIALOG'
  | 'UNLINK_TASK'
  | 'WARN_DEPENDENCY'
  | 'LINK_TASKS'
  | 'ADD_WASH_COUNTS'
  | 'UPSERT_EMPLOYEE'
  | 'CREATE_USER'
  | 'EDIT_USER'
  | 'UPSERT_SHIFT'
  | 'UPSERT_UTILITY'
  | 'UPSERT_INVENTORY'
  | 'DELETE_INVENTORY'
  | 'UPSERT_DETERGENT_DATA'
  | 'UPSERT_FEEDBACK'
  | 'CLOSE_WORK_LOG'
  | 'OPEN_WORK_LOG'
  | 'UPSERT_WASH_COUNTER'
  | 'UPSERT_PRODUCT'
  | 'ADJUST_POS_COUNT'
  | 'SHOW_ADJUSTMENT_NOTES'
  | 'UPSERT_FILE'
  | 'REPAIR_DATA_DIALOG'
  | 'DECLINE_ISSUE_SITE'
  | 'ADD_NOTE'
  | 'ADD_PURCHASE_ORDER'
  | 'ADD_VENDOR';

const MODAL_COMPONENTS: Record<ModalType, any> = {
  TODAYS_TASKS_DIALOG: TodaysTasksDialog,
  UPSERT_TASK: UpsertTaskDialog,
  UPSERT_PROJECT: UpsertProjectDialog,
  COMPLETE_TASK: CompleteTaskDialog,
  COMPLETE_TASK_AOT: CompleteTaskAOTDialog,
  ADD_DETERGENT: AddDetergentDialog,
  ADD_DETERGENT_READING: AddDetergentReadingDialog,
  EDIT_DETERGENT: EditDetergentDialog,
  CONFIRM_DIALOG: ConfirmDialog,
  ADD_WASH_COUNTS: AddWashCountDialog,
  UPSERT_EMPLOYEE: UpsertEmployeesDialog,
  UPSERT_SHIFT: UpsertShiftDialog,
  UPSERT_UTILITY: UpsertUtilitiesDialog,
  UPSERT_INVENTORY: UpsertInventoryDialog,
  DELETE_INVENTORY: DeleteInventoryDialog,
  UPSERT_DETERGENT_DATA: UpsertDetergentDataDialog,
  UPSERT_FEEDBACK: UpsertFeedbackDialog,
  CLOSE_WORK_LOG: CloseWorkLogDialog,
  OPEN_WORK_LOG: OpenWorkLogDialog,
  UPSERT_WASH_COUNTER: UpsertWashCounterDialog,
  UPSERT_PRODUCT: UpsertProductDialog,
  ADJUST_POS_COUNT: AdjustPOSCountDialog,
  SHOW_ADJUSTMENT_NOTES: AdjustmentDetailDialog,
  UPSERT_FILE: UpsertFileDialog,
  REPAIR_DATA_DIALOG: RepairDataDialog,
  DECLINE_ISSUE_SITE: DeclineIssueDialog,
  ADD_NOTE: NoteDialog,
  ADD_PURCHASE_ORDER: PurchaseOrderDialog,
  ADD_VENDOR: AddVendorDialog,
  CREATE_USER: CreateUserDialog,
  EDIT_USER: EditUserDialog,
  WARN_DEPENDENCY: DependencyWarningDialog,
  UNLINK_TASK: UnlinkTaskDialog,
  LINK_TASKS: TaskLinkingModalDialog,
};

interface ModalProps {
  modalType: ModalType;
  modalProps: any;
}

export /**
 * ModalRoot component to render the modal component based on the modalType from the store
 * @return {any}  {any}
 * @return {*}  {*}
 */
const ModalRoot = (): any => {
  const modalState: ModalProps = useSelector(selectModal);

  const [modalComponent, setModalComponent] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (modalState.modalType) {
      setModalComponent(() => MODAL_COMPONENTS[modalState.modalType]);
      setIsOpen(true);
    } else {
      setModalComponent(null);
      setIsOpen(false);
    }
  }, [modalState.modalType]);

  const isModalClosed = !isOpen || !modalComponent || !modalState.modalType;

  if (isModalClosed) {
    return null;
  }

  // if the modalProps has a specificComponent, render that component instead
  if (modalState.modalProps?.specificComponent) {
    const SpecificComponent = modalState.modalProps.specificComponent;
    const props = omit(modalState.modalProps, 'specificComponent');
    return <SpecificComponent isOpen={isOpen} {...props} />;
  }

  const ModalComponent = modalComponent;

  return <ModalComponent isOpen={isOpen} {...modalState.modalProps} />;
};
