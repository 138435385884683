import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getApiStatus } from '../utils';
import { selectSite, selectAlerts } from '../../store/authReducer';
import { selectMultiSiteSelection } from '../../views/site-selection/reducer';
import { acknowledgeAlert, fetchAlerts } from '../../views/site-selection/actions';
import { useIntl } from 'react-intl';
import Loader from './Loader';

interface Alert {
  id: number;
  name: string;
  description: string;
  siteId: number;
  detergentId: number;
}

export const Alerts = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const alerts = useSelector(selectAlerts),
    primarySiteId = useSelector(selectSite),
    multiSiteSelection = useSelector(selectMultiSiteSelection),
    loadingApiCall = useSelector((state) => getApiStatus(state, 'FETCH_ALERTS'));

  const getSiteNotifications = () => {
    dispatch(fetchAlerts());
  };

  const handleAcknowledgeAlert = (id: number) => {
    dispatch(acknowledgeAlert(id));
  };

  const handleNotificationList = () => {
    if (loadingApiCall) {
      return <Loader />;
    }
    return (
      <div>
        {alerts.map((n: Alert, i: any) => (
          <div className="alert-item" key={n.id} role="menuitem">
            <input
              data-testid={`alert-#${i}`}
              className="alert-input-box"
              type="checkbox"
              onClick={() => handleAcknowledgeAlert(n.id)}
            />
            <span>{n.description}</span>
          </div>
        ))}
      </div>
    );
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(getSiteNotifications, [primarySiteId, multiSiteSelection]);

  return (
    <div className="alert-container">
      {alerts?.length ? (
        <span className="alert-number" data-testid="alerts-number">
          {alerts?.length}
        </span>
      ) : (
        ''
      )}
      <Dropdown className="n-main-dropdown" isOpen={open} toggle={toggleOpen}>
        <DropdownToggle
          className={`btn btn-secondary alert-button${alerts?.length ? ' has-alerts' : ''}`}
        >
          <i className="icon icon-bell"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right" right>
          <div data-testid="alerts-dropdown-content">
            {alerts?.length ? (
              <div>{handleNotificationList()}</div>
            ) : (
              <p role="menuitem">{intl.formatMessage({ id: 'noAlerts' })}</p>
            )}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default Alerts;
