import { Controller } from 'react-hook-form';
import moment from 'moment';
import DateTimePicker from '../../../core/components/DateTimePicker';
import { useEffect, useState } from 'react';

interface VendingSalesRowProps {
  site: any;
  form: any;
  index: number;
  validCallback: any;
  intl: any;
}

const VendingSalesRow = ({ site, form, index, validCallback, intl }: VendingSalesRowProps) => {
  const [valid, setValid] = useState(false);
  const amountSelected = form.watch(`sales[${index}].amount`);
  const dateSelected = form.watch(`sales[${index}].statementPeriod`);
  const isValidField = (fieldValue: any) => {
    return fieldValue && fieldValue != '';
  };
  const isWeekday = (date: any) => {
    const day = moment(date).day();
    return day !== 0 && day !== 6;
  };
  useEffect(() => {
    if (isValidField(amountSelected) && isValidField(dateSelected)) {
      setValid(true);
      validCallback(site.id, true);
    } else {
      setValid(false);
      validCallback(site.id, false);
    }
  }, [amountSelected, dateSelected]);
  return (
    <div className="form-row">
      <p>
        <i className={`uil uil-check-circle form-checkmark${valid ? ' visible' : ''}`}></i>
        {site.externalId && <>{site.externalId} - </>}
        {site.name}
      </p>
      <div>
        <div className="form-group">
          <label>{intl.formatMessage({ id: 'chooseWeek' })}</label>
          <Controller
            name={`sales[${index}].statementPeriod`}
            control={form.control}
            render={({ field }) => {
              //Silence error with 'forwardRef': https://github.com/orgs/react-hook-form/discussions/8015
              const { ref, ...filteredField } = field;
              return (
                <DateTimePicker
                  input={{ value: field.value, onChange: field.onChange }}
                  {...filteredField}
                  timeProps={{
                    dateFormat: 'I/YYYY',
                    filterDate: isWeekday,
                    showWeekPicker: true,
                  }}
                />
              );
            }}
          />
        </div>
        <div className="form-group vending-totals">
          <label>{intl.formatMessage({ id: 'enterTotals' })}</label>
          <input
            {...form.register(`sales[${index}].amount`, {
              valueAsNumber: true,
              min: 0,
            })}
            className="form-control"
            type="number"
            step="0.01"
          />
        </div>
      </div>
      <input
        {...form.register(`sales[${index}].siteId`, {
          valueAsNumber: true,
        })}
        defaultValue={site.id}
        className="form-control hidden"
      />
    </div>
  );
};

export default VendingSalesRow;
